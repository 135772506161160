import React, { useEffect, useState } from 'react';
import HeaderDashboard from '../header/headerDashboard';
import { Table } from 'react-bootstrap';

const Histories = () => {
    const [histories, setHistories] = useState([]);
    const [openPayments, setOpenPayments] = useState([]);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/histories/`, {
            method: 'GET',
            credentials: 'include',
            headers: new Headers({
                'Content-Type': 'application/json',
            })
        })
            .then(async (res) => {
                if (res.status === 200) return res.json();
                throw await res.json();
            })
            .then((res) => {
                setHistories(res);
            }).catch((err) => {
                console.log(err);
            });
    }, []);

    // Trouver tous les index des paiements
    const paymentIndexes = histories
        .map((h, index) => ({ ...h, index }))
        .filter(h => h.status === 'Paiement')
        .map(h => h.index);

    // Trouver le dernier paiement
    const lastPaymentIndex = paymentIndexes.length > 0 ? paymentIndexes[paymentIndexes.length - 1] : -1;

    // Lits livrés après le dernier paiement (non encore payés)
    const unpaidBeds = lastPaymentIndex !== -1 ? histories.slice(lastPaymentIndex + 1) : histories;

    // Fonction pour gérer l'ouverture/fermeture des paiements
    const togglePayment = (index) => {
        setOpenPayments(openPayments.includes(index)
            ? openPayments.filter(i => i !== index)
            : [...openPayments, index]);
    };

    return (
        <div>
            <HeaderDashboard />
            <h1>Historique</h1>

            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Numéro</th>
                        <th>Status</th>
                        <th>Date</th>
                        <th>Client</th>
                        <th>Pharmacie</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {/* Affichage des paiements */}
                    {paymentIndexes.map((paymentIndex, i) => {
                        const isOpen = openPayments.includes(paymentIndex);
                        const previousPaymentIndex = i > 0 ? paymentIndexes[i - 1] : -1;

                        // Lits livrés entre ce paiement et le paiement précédent
                        const bedsBetweenPayments = histories.slice(previousPaymentIndex + 1, paymentIndex);

                        return (
                            <React.Fragment key={paymentIndex}>
                                <tr
                                    className="table-info"
                                    onClick={() => togglePayment(paymentIndex)}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <td>{histories[paymentIndex].number}</td>
                                    <td>{histories[paymentIndex].status}</td>
                                    <td>{new Date(histories[paymentIndex].createdAt).toLocaleDateString('fr-FR')}</td>
                                    <td>{histories[paymentIndex].client}</td>
                                    <td>{histories[paymentIndex].pharmacy}</td>
                                    <td>{isOpen ? '▲' : '▼'}</td>
                                </tr>

                                {/* Si ouvert, afficher les lits livrés entre ce paiement et le paiement précédent */}
                                {isOpen && bedsBetweenPayments.map((bed, j) => (
                                    <tr key={`bed-${j}`} className="table-light">
                                        <td>{bed.number}</td>
                                        <td>{bed.status}</td>
                                        <td>{new Date(bed.createdAt).toLocaleDateString('fr-FR')}</td>
                                        <td>{bed.client}</td>
                                        <td>{bed.pharmacy}</td>
                                        <td>-</td>
                                    </tr>
                                ))}
                            </React.Fragment>
                        );
                    })}

                    {/* Affichage des lits non encore payés (après le dernier paiement) */}
                    {unpaidBeds.map((bed, i) => (
                        <tr key={i} className="table-warning">
                            <td>{bed.number}</td>
                            <td>{bed.status}</td>
                            <td>{new Date(bed.createdAt).toLocaleDateString('fr-FR')}</td>
                            <td>{bed.client}</td>
                            <td>{bed.pharmacy}</td>
                            <td>-</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );
};

export default Histories;
